import { nanoid } from 'nanoid'
import { ApiConfig } from '@chilipiper/api-client/src/http-client'
import { jwtApiV2, redirectToLogin as v2RedirectToLogin } from '@chilipiper/service/src/jwtApiV2'
import { isFireAuth } from '@chilipiper/rollout'
import { jwtApiV2WithV1Fallback } from './jwtApiV2WithV1Fallback'

const cameFromAuthService = (url: RequestInfo | URL) => url.toString().includes('/fire-auth')

export const sanitizeErrors = async (response: Response) => {
  if (!response.ok) {
    try {
      await response.clone().json()
      return response
    } catch {
      const responseError = {
        statusText: response.statusText,
        status: response.status,
      }
      throw responseError
    }
  }
  return response
}

export const setupAuthHeadersV2 = (init: RequestInit, needAuth: boolean = true) => {
  init.headers = <Record<string, string>>{
    ...init.headers,
    'x-request-id': nanoid(),
  }

  const token = jwtApiV2.getToken()
  if (needAuth) {
    if (!init.headers.authorization && token) {
      init.headers.authorization = `Bearer ${token}`
    }
  } else {
    delete init.headers.authorization
  }
}

const publicPathRegExp = /\/api\/.+\/v\d+\/public\//

export const isPublicRequest = (input: RequestInfo | URL) => {
  if (typeof input === 'string') {
    return publicPathRegExp.test(input)
  }

  if ((input as URL).pathname) {
    publicPathRegExp.test((input as URL).pathname)
  }

  if ((input as Request).url) {
    publicPathRegExp.test((input as Request).url)
  }

  return false
}

export const getApiV2Config = (): ApiConfig => {
  return {
    customFetch: async (input, init = {}) => {
      setupAuthHeadersV2(init, !isPublicRequest(input))
      return sanitizeErrors(await refreshTokenMiddlewareV2(input, init))
    },
  }
}

export const refreshTokenMiddlewareV2 = async (input: RequestInfo | URL, init: RequestInit) => {
  const response = await fetch(input, init)
  try {
    if (![401, 403].includes(response.status)) {
      return response
    }
    if (cameFromAuthService(input)) {
      v2RedirectToLogin()
      return response
    }
    await jwtApiV2.login().catch(err => {
      if (err instanceof Response && [401, 403].includes(err.status)) {
        v2RedirectToLogin()
      }
    })
    if (!isPublicRequest(input)) {
      init.headers = {
        ...init.headers,
        authorization: `Bearer ${jwtApiV2.getToken()}`,
      }
    }
    return fetch(input, init)
  } catch (err) {
    console.error(err)
    return response
  }
}

export const getApiV1Config = (): ApiConfig => ({
  customFetch: async (input, init: RequestInit = {}) => {
    init.headers = <Record<string, string>>{
      ...init.headers,
      'x-request-id': nanoid(),
    }

    const token = jwtApiV2WithV1Fallback.getToken()
    if (!isPublicRequest(input) && token) {
      init.headers.authorization = `Bearer ${token}`
    }

    return sanitizeErrors(await refreshTokenMiddlewareV1(input, init))
  },
})

const v1RedirectToLogin = () => {
  const auth_path = `/login.html`
  window.location.href = `${window.location.origin}${auth_path}`
}

const refreshTokenMiddlewareV1 = async (input: RequestInfo | URL, init: RequestInit) => {
  const response = await fetch(input, init)
  try {
    if ([401, 403].includes(response.status)) {
      // no need to check cameFromAuthService as in v1 we never use auth service v2
      await jwtApiV2WithV1Fallback.refresh().catch(() => {
        v1RedirectToLogin()
      })

      if (!isPublicRequest(input)) {
        init.headers = {
          ...init.headers,
          authorization: `Bearer ${jwtApiV2WithV1Fallback.getToken()}`,
        }
      }
      return fetch(input, init)
    }
    return response
  } catch (err) {
    console.error(err)
    return response
  }
}

export const getApiConfig = (forceLegacyAuth?: boolean) =>
  isFireAuth() && !forceLegacyAuth ? getApiV2Config() : getApiV1Config()
